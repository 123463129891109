/* Placeholder wrapper */
.blog-list-image-wrapper {
    position: relative;
    width: auto;
    height: 350px; /* Adjust to your preferred height */
    overflow: hidden;
  }
  
  /* Placeholder effect */
  .blog-list-image {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  /* Shimmer animation */
  @keyframes shimmer {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  
  /* Image styles */
  .actual-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    transition: opacity 0.3s ease-in-out;
  }
  
  .actual-image.hidden {
    opacity: 0;
  }
  
  .actual-image.loaded {
    opacity: 1;
  }
  