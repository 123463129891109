/* Wrapper ensures the placeholder doesn't alter image size */
.image-wrapper {
    position: relative;
    display: inline-block;
    width: 100%; /* Ensures the image retains its dimensions */
    height: 100%;
    overflow: hidden;
  }
  
  /* Placeholder effect */
  .image-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    z-index: 1;
    border: 2px solid #ccc; /* Optional: Border for placeholder */
    border-radius: 16px; /* Optional: Match the image's border-radius */
  }
  
  /* Shimmer animation */
  @keyframes shimmer {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  
  /* Specific styles for placeholder images */
  .placeholder-hidden {
    opacity: 0;
  }
  
  .placeholder-loaded {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  